import  Main from"./comp/Main"

function App() {
  return (
    <div className="App">
  < Main/>
  
    </div>
  );
}

export default App;

